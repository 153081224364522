import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './modules/App';

import './index.css';
import './assets/css/custom.css';

// Slick
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import './assets/js/scripts.js?ver=141';

// Styles
import './assets/css/vendor.bundle.css?ver=141';
import './assets/css/style.css?ver=141';
import './assets/css/theme.css?ver=141';

import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import reportWebVitals from './reportWebVitals';

// Scripts
// import './assets/js/jquery.bundle.js?ver=141';
import './assets/js/scripts.js?ver=141';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
