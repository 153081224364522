import React, { useEffect } from 'react';

import Header from '../../components/Header';
import WhatWeDo from '../../components/WhatWeDo';
import WhoWeAre from '../../components/WhoWeAre';
import AboutSection from '../../components/AboutSection';
import ProcessSection from '../../components/ProcessSection';
import WorkSection from '../../components/WorkSection';
import TestimonialsSection from '../../components/TestimonialsSection';
// import TeamSection from '../../components/TeamSection';
import ClientsSection from '../../components/ClientsSection';
// import NumbersSection from '../../components/NumbersSection';
// import NewsSection from '../../components/NewsSection';
import CTASection from '../../components/CtaSection';

import projects from '../../data/projects';
import categories from '../../data/categories';

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Header />
      <WhatWeDo />
      <WhoWeAre />
      <AboutSection />
      <ProcessSection />
      <WorkSection projects={projects} categories={categories} />
      <ClientsSection />
      <TestimonialsSection />
      {/*<TeamSection />*/}
      {/*<NumbersSection />*/}
      {/*<NewsSection />*/}
      <CTASection />
    </div>
  );
};

export default Home;
