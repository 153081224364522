import React, { useEffect } from 'react';

import Banner from '../../components/Banner';
import { backgrounds } from '../../assets';
import services from '../../data/services.json';
import ClientsSection from '../../components/ClientsSection';
import CTASection from '../../components/CtaSection';

const Services = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Banner
        title="REAL SOLUTIONS FOR YOUR BUSINESS NEEDS"
        image={backgrounds.header4}
        ctaText={"Let's talk"}
      />

      <div className="section section-x tc-bunker">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-md-5">
              <div className="section-head section-md res-m-btm">
                <h5 className="heading-xs dash">OUR SERVICES</h5>
                <h2>
                  We offer a wide range of services to help you grow your
                  product
                </h2>
              </div>
            </div>
            <div className="col-md-6">
              <div className="section-head section-md">
                <div className="text-box">
                  <p className="lead">
                    Our people's bravery is the secret ingredient behind our
                    success - not just our track record, process efficiency, and
                    product quality
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center gutter-vr-30px">
            {services.map((service, index) => (
              <div key={index} className="col-sm-6 col-xl-4">
                <div className="feature feature-s2 feature-s2-inner bdr">
                  <div className="feature-icon">
                    <em className={service.icon}></em>
                  </div>
                  <div className="feature-content-s3">
                    <h3>{service.title}</h3>
                    <p>{service.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="section section-x tc-bunker has-bg-image">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-7 text-center">
              <div className="section-head section-md">
                <h5 className="heading-xs dash dash-both">Our process</h5>
                <h2>
                  We know the best results come from bold ideas. We enjoy what
                  we do and we want you to too.
                </h2>
              </div>
            </div>
          </div>
          <div className="row justify-content-center gutter-vr-30px">
            <div className="col-lg-4 col-md-6 text-center">
              <div className="feature feature-s2 feature-s2-alt-inner bg-secondary">
                <div className="icon-box">
                  <strong className="icon">01</strong>
                  <div className="icon-box-content">
                    <h6 className="tc-primary">Discovery mission</h6>
                  </div>
                </div>
                <div className="feature-content">
                  <p>
                    We start in understanding your business and your needs. This
                    is included in our free quota.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 text-center">
              <div className="feature feature-s2 feature-s2-alt-inner bg-secondary">
                <div className="icon-box">
                  <strong className="icon">02</strong>
                  <div className="icon-box-content">
                    <h6 className="tc-primary">Project quota</h6>
                  </div>
                </div>
                <div className="feature-content">
                  <p>
                    We will provide the estimations based on the overall
                    development effort, broken down on development phases. This
                    is included in our free quota.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 text-center">
              <div className="feature feature-s2 feature-s2-alt-inner bg-secondary">
                <div className="icon-box">
                  <strong className="icon">03</strong>
                  <div className="icon-box-content">
                    <h6 className="tc-primary">Phase based development</h6>
                  </div>
                </div>
                <div className="feature-content">
                  <p>
                    We divide your project into different phases of its
                    development so it’s easier to choose what goes in and what
                    goes out or is postponed from your product based on your
                    needs and budget.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 text-center">
              <div className="feature feature-s2 feature-s2-alt-inner bg-secondary">
                <div className="icon-box">
                  <strong className="icon">04</strong>
                  <div className="icon-box-content">
                    <h6 className="tc-primary">Incremental development</h6>
                  </div>
                </div>
                <div className="feature-content">
                  <p>
                    We deliver frequently and on time the increments that are
                    planned within the roadmap. After an agreed upon time, we
                    showcase those functionalities within product demos.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 text-center">
              <div className="feature feature-s2 feature-s2-alt-inner bg-secondary">
                <div className="icon-box">
                  <strong className="icon">05</strong>
                  <div className="icon-box-content">
                    <h6 className="tc-primary">Reiterate</h6>
                  </div>
                </div>
                <div className="feature-content">
                  <p>
                    We gather data based on your feedback, customer feedback,
                    analytics and other crucial data to create your vision and
                    what your customers want.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 text-center">
              <div className="feature feature-s2 feature-s2-alt-inner bg-secondary">
                <div className="icon-box">
                  <strong className="icon">06</strong>
                  <div className="icon-box-content">
                    <h6 className="tc-primary">Transparency</h6>
                  </div>
                </div>
                <div className="feature-content">
                  <p>
                    Milestones, product progress, requirements, everything is
                    reported within Jira/Confluence or in a different set of
                    tools, based on your own needs.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="bg-image bg-fixed overlay-dark-light overlay-fall bg-image-loaded"
          style={{
            backgroundImage: `url(${backgrounds.numbersBge})`,
          }}
        >
          <img src={backgrounds.numbersBge} alt="Our process in development" />
        </div>
      </div>

      <ClientsSection />
      <CTASection />
    </div>
  );
};

export default Services;
