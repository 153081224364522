import { images } from '../assets';

const testimonials = [
  {
    name: 'Zveinn Ottar Larruson',
    image: images.testimonials.sveinn_t,
    position: 'CEO - Sveinn EHF',
    text: 'I highly recommend Software From Space for any software development needs. Their employees are professional, calm and collected. The quality of their work is exceptional, and communication has always been top-notch.',
    title: 'Excellent team',
  },
  // {
  //   name: 'Adrian Boros',
  //   image: images.testimonials.kidgarten_t,
  //   position: 'CEO - Kidgarten',
  //   text: 'These guys are legit. I’ll never hire another agency as long as I’m working. They are also extremely helpful and amazing.',
  //   title: 'asdsa',
  // },
  // {
  //   name: 'Alin Stanescu',
  //   image: images.testimonials.salon_t,
  //   position: 'CEO - Salon365',
  //   text: 'These guys are legit. I’ll never hire another agency as long as I’m working. They are also extremely helpful and amazing.',
  //   title: 'They are great',
  // },
];

export default testimonials;
