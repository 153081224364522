import React from 'react';
import {backgrounds, images} from '../../assets';
import Slider from 'react-slick';

import testimonials from '../../data/testimonials';

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
    >
      <i className="icon ti ti-angle-right" />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
    >
      <i className="icon ti ti-angle-left" />
    </div>
  );
}

const TestimonialsSection = () => {
  let settings = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  return (
    <div className="section section-x tc-bunker ">
      <div className="container">
        <div className="row justify-content-center ">
          <div className="col-lg-8 text-center">
            <div className="section-head section-md">
              <h5 className="heading-xs dash dash-both">Testimonial</h5>
              <h2>What clients say about us</h2>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <div className="tes-s1">
              <div
                className="has-carousel"
                data-items="1"
                data-loop="true"
                data-dots="true"
                data-auto="true"
                data-navs="true"
                data-margin="30"
              >
                <Slider {...settings}>
                  {testimonials.map((testimonial, index) => (
                    <div key={index} className="tes-item">
                      <div className="row">
                        <div className="col-md-5">
                          <div className="tes-thumb">
                            <div className="bg-image bg-image-loaded" style={{
                              backgroundImage: `url(${testimonial.image})`,
                            }}>
                              <img src={testimonial.image} alt="" />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-7">
                          <div className="tes-block tc-light bg-primary">
                            <div className="tes-content">
                              <p className="tes-title u-cap">
                                <strong>{testimonial.title}</strong>
                              </p>
                              <p className="lead">{testimonial.text}</p>
                            </div>
                            <div className="author-con">
                              <h6 className="author-name t-u">
                                {testimonial.name}
                              </h6>
                              <p>{testimonial.position}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestimonialsSection;
