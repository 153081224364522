import React, {useEffect} from 'react';
import {backgrounds, images} from '../../assets';
import Banner from '../../components/Banner';
import ClientsSection from '../../components/ClientsSection';
import {NavLink} from "react-router-dom";

const About = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>
            <Banner
                title="WE AIM TOWARDS PERFECTION"
                subtitle="Your milestones are our goals. We focus on meeting your internal key timelines from the product’s phase."
                image={backgrounds.singleProject}
            />

            <div className="section section-x tc-bunker">
                <div className="container">
                    <div className="row gutter-vr-30px">
                        <div className="col-md-12">
                            <div className="text-block pr-2rem">
                                <h5 className="heading-xs dash t-u"></h5>
                                <h2>
                                    We stick by our values
                                </h2>
                                <p className="lead">
                                    We are enthusiastic about technology, champions of an active lifestyle, and enjoy
                                    socializing. We possess a diverse range of personalities and characteristics, with
                                    some of us being introverted while others are extroverted. We exhibit courage and
                                    fearlessness, and we represent kindness.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-12 tc-grey">
                            <div className="text-block">
                                <div className="row gutter-vr-20px">
                                    <div className="col-12 col-md-6 col-lg-4">
                                        <div className="text-box project-box-pad bg-secondary h-full">
                                            <h4>Honest & Transparent
                                            </h4>
                                            <p>
                                                We will always offer our honest feedback based on where we see the
                                                product and how to make it better,
                                                while offering full transparency and code access to everything that we
                                                do. .
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-4">
                                        <div className="text-box project-box-pad bg-secondary h-full">
                                            <h4>Passionate</h4>
                                            <p>
                                                We are guided by passion. Plain and simple.
                                                We love what we do and we strongly believe that this is seen in the
                                                software we deliver.
                                                We are your partners in making sure your product is a success.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-4">
                                        <div className="text-box project-box-pad bg-secondary h-full">
                                            <h4>Family</h4>
                                            <p>
                                                We love to laugh and have fun while working together. We strongly
                                                believe that friendships can be created during our collaboration and
                                                even beyond. It's always easy to communicate with us.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section section-x tc-bunker  has-bg-image">
                <div className="container">
                    <div className="row justify-content-end">
                        <div className="col-md-10 col-lg-5">
                            <div className="text-block bg-secondary block-pad-80">
                                <h5 className="heading-xs dash">Why Choose us</h5>
                                <h2>
                                    We love to make a difference by creating digital experiences
                                    that simplify and enhance value.
                                </h2>
                                <NavLink to={"/services"} className="btn rounded-sm">
                                    Our Services
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="bg-image bg-fixed  bg-image-loaded"
                     style={{
                         backgroundImage: `url(${backgrounds.numbersBg})`,
                     }}>
                    <img src={backgrounds.numbersBg} alt=""/>
                </div>
            </div>

            {/*<div className="section section-counter-m tc-bunker">*/}
            {/*  <div className="container">*/}
            {/*    <div className="row align-items-center gutter-vr-30px justify-content-center">*/}
            {/*      <div className="col-md-3 col-sm-6 col-6">*/}
            {/*        <div className="counter">*/}
            {/*          <div className="counter-icon">*/}
            {/*            <em className="icon ti-dropbox"></em>*/}
            {/*          </div>*/}
            {/*          <div className="counter-content">*/}
            {/*            <h2 className="count" data-count="34">*/}
            {/*              34*/}
            {/*            </h2>*/}
            {/*            <p className="mb-0">Brands Helped</p>*/}
            {/*          </div>*/}
            {/*        </div>*/}
            {/*      </div>*/}
            {/*      <div className="col-md-3 col-sm-6 col-6">*/}
            {/*        <div className="counter">*/}
            {/*          <div className="counter-icon">*/}
            {/*            <em className="icon ti-basketball"></em>*/}
            {/*          </div>*/}
            {/*          <div className="counter-content">*/}
            {/*            <h2 className="count" data-count="145">*/}
            {/*              +145*/}
            {/*            </h2>*/}
            {/*            <p className="mb-0">Ongoing Task</p>*/}
            {/*          </div>*/}
            {/*        </div>*/}
            {/*      </div>*/}
            {/*      <div className="col-md-3 col-sm-6 col-6">*/}
            {/*        <div className="counter">*/}
            {/*          <div className="counter-icon">*/}
            {/*            <em className="icon ti-pencil-alt"></em>*/}
            {/*          </div>*/}
            {/*          <div className="counter-content">*/}
            {/*            <h2 className="count" data-count="437">*/}
            {/*              +437*/}
            {/*            </h2>*/}
            {/*            <p className="mb-0">Projects Done</p>*/}
            {/*          </div>*/}
            {/*        </div>*/}
            {/*      </div>*/}
            {/*      <div className="col-md-3 col-sm-6 col-6">*/}
            {/*        <div className="counter">*/}
            {/*          <div className="counter-icon">*/}
            {/*            <em className="icon ti-user"></em>*/}
            {/*          </div>*/}
            {/*          <div className="counter-content">*/}
            {/*            <h2 className="count" data-count="375">*/}
            {/*              +375*/}
            {/*            </h2>*/}
            {/*            <p className="mb-0">Satisfied Clients</p>*/}
            {/*          </div>*/}
            {/*        </div>*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</div>*/}

            {/*<div className="section section-x team bg-bunker tc-bunker">*/}
            {/*  <div className="container">*/}
            {/*    <div className="row justify-content-center justify-content-sm-start gutter-vr-30px">*/}
            {/*      <div className="col-lg-9 col-xl-3 text-center text-sm-left pl-sm-0">*/}
            {/*        <div className="section-head section-head-col box-pad-sm">*/}
            {/*          <h5 className="heading-xs dash">Meet the team</h5>*/}
            {/*          <h2>People of behind work</h2>*/}
            {/*          <p className="lead mb-10">*/}
            {/*            We’re always expanding hard working creators.*/}
            {/*          </p>*/}
            {/*          <a href="dallas-dark-team.html" className="btn roun">*/}
            {/*            Meet All Team*/}
            {/*          </a>*/}
            {/*        </div>*/}
            {/*      </div>*/}

            {/*      <div className="col-lg-4 col-xl-3 col-sm-4 col-10">*/}
            {/*        <div className="team-single text-center">*/}
            {/*          <div className="team-image">*/}
            {/*            <img src={images.team} alt="" />*/}
            {/*          </div>*/}
            {/*          <div className="team-content">*/}
            {/*            <h5 className="team-name">Andrew rover</h5>*/}
            {/*            <p>CEO, Genox</p>*/}
            {/*          </div>*/}
            {/*        </div>*/}
            {/*      </div>*/}
            {/*      <div className="col-lg-4 col-xl-3 col-sm-4 col-10">*/}
            {/*        <div className="team-single text-center">*/}
            {/*          <div className="team-image">*/}
            {/*            <img src={images.team} alt="" />*/}
            {/*          </div>*/}
            {/*          <div className="team-content">*/}
            {/*            <h5 className="team-name">Marina Bedi</h5>*/}
            {/*            <p>Developer, Genox</p>*/}
            {/*          </div>*/}
            {/*        </div>*/}
            {/*      </div>*/}
            {/*      <div className="col-lg-4 col-xl-3 col-sm-4 col-10">*/}
            {/*        <div className="team-single text-center">*/}
            {/*          <div className="team-image">*/}
            {/*            <img src={images.team} alt="" />*/}
            {/*          </div>*/}
            {/*          <div className="team-content">*/}
            {/*            <h5 className="team-name">Ajax Holder</h5>*/}
            {/*            <p>Head of Research, Genox</p>*/}
            {/*          </div>*/}
            {/*        </div>*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</div>*/}

            <ClientsSection/>

            <div className="section section-x bg-bunker tc-bunker">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-12 text-center">
                            <div className="section-head section-sm">
                                <h5 className="heading-xs dash dash-both">Why us</h5>
                                <h2>What makes us unique</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-lg-3 col-md-6 text-center">
                            <div className="feature feature-s2">
                                <div className="feature-icon-box">
                                    <div className="feature-icon feature-icon-s3">
                                        <em className="icon ti-crown"></em>
                                    </div>
                                    <div className="feature-heading feature-heading-s2">
                                        <h3>Quality</h3>
                                    </div>
                                </div>
                                <div className="feature-content-s2">
                                    <p>
                                        We offer our experience and know-how to provide the best quality a software that
                                        can be built and we will always test and test and re-test our delivered
                                        functionalities.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 text-center">
                            <div className="feature feature-s2">
                                <div className="feature-icon-box">
                                    <div className="feature-icon feature-icon-s3">
                                        <em className="icon ti-time"></em>
                                    </div>
                                    <div className="feature-heading feature-heading-s2">
                                        <h3>Cost/Time efficient</h3>
                                    </div>
                                </div>
                                <div className="feature-content-s2">
                                    <p>
                                        We will always look to minimize the costs for our clients, by providing them
                                        solutions through external tools that meet their needs, rather than building
                                        everything from scratch.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 text-center">
                            <div className="feature feature-s2">
                                <div className="feature-icon-box">
                                    <div className="feature-icon feature-icon-s3">
                                        <em className="icon ti-briefcase"></em>
                                    </div>
                                    <div className="feature-heading feature-heading-s2">
                                        <h3>Full ownership</h3>
                                    </div>
                                </div>
                                <div className="feature-content-s2">
                                    <p>
                                        We treat your product as it would be our own. We take full ownership on what’s
                                        being built and deliver something that you would be fully satisfied in calling
                                        “Yes, this is my vision!".
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 text-center">
                            <div className="feature feature-s2">
                                <div className="feature-icon-box">
                                    <div className="feature-icon feature-icon-s3">
                                        <em className="icon ti-cup"></em>
                                    </div>
                                    <div className="feature-heading feature-heading-s2">
                                        <h3>Technical expertise</h3>
                                    </div>
                                </div>
                                <div className="feature-content-s2">
                                    <p>
                                        We have experience in the vast majority of tech industries and we’ve seen things
                                        going extremely good or very bad, that’s why we’re going to do our best to avoid
                                        anything that would cause harm to the software we’re building.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="section section-cta bg-primary tc-light">
                <div className="container">
                    <div className="row gutter-vr-30px align-items-center justify-content-between">
                        <div className="col-lg-8 text-center text-lg-left">
                            <div className="cta-text">
                                <h2>
                                    Like what you see? <strong> Let’s work together </strong>
                                </h2>
                            </div>
                        </div>
                        <div className="col-lg-4 text-lg-right text-center">
                            <div className="cta-btn">
                                <NavLink to={"/contact"} className="btn rounded-sm">
                                    Contact us
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default About;
