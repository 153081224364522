import React from 'react';
import { backgrounds } from '../../assets';

const WhoWeAre = () => {
  return (
    <div className="section section-x section-block tc-bunker bg-bunker has-bg-image">
      <div className="container">
        <div className="row justify-content-end">
          <div className="col-lg-9">
            <div className="text-block feature-area bg-darker">
              <div className="section-head">
                <h5 className="heading-xs dash">Who We are</h5>
                <h2>We aim towards perfectionism </h2>
              </div>
              <div className="row gutter-vr-40px">
                <div className="col-sm-6">
                  <div className="feature">
                    <div className="feature-icon feature-icon-s2">
                      <em className="icon ti-briefcase"></em>
                    </div>
                    <div className="feature-content feature-content-s4">
                      <h4>Solution</h4>
                      <p>
                        We believe that the best software development happens
                        when we truly understand your needs and goals.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="feature">
                    <div className="feature-icon feature-icon-s2">
                      <em className="icon ti-bookmark-alt"></em>
                    </div>
                    <div className="feature-content feature-content-s4">
                      <h4>Collaboration</h4>
                      <p>
                        Our collaborative approach builds strong, enduring
                        partnerships based on trust, transparency and respect.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="feature">
                    <div className="feature-icon feature-icon-s2">
                      <em className="icon ti-reload"></em>
                    </div>
                    <div className="feature-content feature-content-s4">
                      <h4>Agile</h4>
                      <p>
                        Embracing an agile approach, we work collaboratively
                        with you to deliver high-quality solutions on time and
                        on budget.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="feature">
                    <div className="feature-icon feature-icon-s2">
                      <em className="icon ti-user"></em>
                    </div>
                    <div className="feature-content feature-content-s4">
                      <h4>Human</h4>
                      <p>
                        We prioritizes end-user needs to create intuitive,
                        engaging solutions.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="bg-image overlay-fall bg-image-loaded"
        style={{
          backgroundImage: `url(${backgrounds.whoWeAre})`,
        }}
      >
        <img src={backgrounds.whoWeAre} alt="banner" />
      </div>
    </div>
  );
};

export default WhoWeAre;
