import { images } from '../assets';

const projects = [
  {
    name: 'EngageApp',
    logo: images.clients.engage,
    link: 'https://www.engageapp.today',
    category: 'Mobile',
    projectSquareImage: images.projects.engage,
  },
  {
    name: 'Salon365',
    logo: images.clients.salon365,
    link: 'https://www.salon365.ro',
    category: 'Mobile',
    projectSquareImage: images.projects.salon365,
  },
  {
    name: 'Salon365',
    logo: images.clients.salon365,
    link: 'https://www.salon365.ro',
    category: 'Web',
    projectSquareImage: images.projects.salon365_web,
  },
  // {
  //   name: 'Kidgarten',
  //   logo: images.clients.kidgarten,
  //   link: 'https://www.kidgarten.com',
  //   category: 'Mobile',
  //   projectSquareImage: images.projects.engage,
  // },
  {
    name: 'Tech Stories',
    logo: images.clients.kidgarten,
    link: 'https://www.techstories.me',
    category: 'Web',
    projectSquareImage: images.projects.techstories,
  },
  {
    name: 'Vibesmap',
    logo: images.clients.vibesmap,
    link: 'https://www.vibesmap.co',
    category: 'Mobile',
    projectSquareImage: images.projects.vibesmap,
  },
  {
    name: 'Kidgarten',
    logo: images.clients.kidgarten,
    link: 'https://www.kidgarten.com',
    category: 'Web',
    projectSquareImage: images.projects.kidgarten_web,
  },
];

export default projects;
