import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

import { images } from '../../assets';
import Banner from '../../components/Banner';
import projects from '../../data/projects';
import categories from '../../data/categories';
import { slugify } from '../../utils';

const Projects = () => {
  const [selectedFilter, setSelectedFilter] = useState('All');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Banner
        title={"WE'VE EXPLORED MANY GALAXIES"}
        image={images.headers.work}
        ctaText={"LET'S START A JOURNEY"}
      />

      <div className="section section-x section-project tc-bunker" id="work">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-8 text-center text-md-left">
              <div className="section-head mtm-10">
                <h2>
                  Some highlights of work we've done for forward thinking
                  clients.
                </h2>
              </div>
            </div>
            <div className="col-md-12">
              <ul className="project-filter filter-left project-md text-center text-sm-left">
                {categories.map((category, index) => (
                  <li
                    onClick={() => setSelectedFilter(category.name)}
                    key={index}
                    className={selectedFilter === category.name ? 'active' : ''}
                    data-filter="all"
                  >
                    {category.name}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className="project-area">
          <div className="container">
            <div
              className="row project project-s2 gutter-vr-30px"
              id="project1"
            >
              {projects
                .filter((p) =>
                  selectedFilter === 'All' ? p : p.category === selectedFilter,
                )
                .map((project, index) => (
                  <div
                    key={index}
                    className="col-md-4 col-sm-6 filtr-item rounded-sm"
                    data-category="1"
                  >
                    {/*<NavLink to={`project/${slugify(project.name)}`}>*/}
                      <div className="project-item ">
                        <div className="project-image">
                          <img src={project.projectSquareImage} alt="" />
                        </div>
                        <div className="project-over">
                          <div className="project-content">
                            <h4>{project.name}</h4>
                            <p>{project.category}</p>
                          </div>
                        </div>
                      </div>
                    {/*</NavLink>*/}
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>

      <div className="section section-cta bg-primary tc-light">
        <div className="container">
          <div className="row gutter-vr-30px align-items-center justify-content-between">
            <div className="col-lg-8 text-center text-lg-left">
              <div className="cta-text">
                <h2>
                  Like what you see? <strong> Let’s work </strong>
                </h2>
              </div>
            </div>
            <div className="col-lg-4 text-lg-right text-center">
              <div className="cta-btn">
                <NavLink to="/contact" className="btn rounded-sm">
                  Contact us
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Projects;
