import React, { useState } from 'react';
import { images } from '../../assets';
import { slugify } from '../../utils';
import { NavLink } from 'react-router-dom';

const WorkSection = ({ projects, categories }) => {
  const [selectedFilter, setSelectedFilter] = useState('All');

  return (
    <div
      className="section section-x section-project pb-0 tc-bunker"
      id="project"
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-7 text-center">
            <div className="section-head">
              <h5 className="heading-xs dash dash-both">Featured Work</h5>
              <h2>
                Our mission is to deliver remarkable experiences and superior
                results for our clients{' '}
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-12">
            <ul className="project-filter project-md">
              {categories.map((category, index) => (
                <li
                  className={selectedFilter === category.name ? 'active' : ''}
                  data-filter={category.name}
                  key={index}
                  onClick={() => setSelectedFilter(category.name)}
                >
                  {category.name}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div className="project-area bg-secondary">
        <div className="row project gutter-vr-30px" id="project1">
          {projects
            .filter((p) =>
              selectedFilter === 'All' ? p : p.category === selectedFilter,
            )
            .map((project, index) => (
              <div
                className="col-sm-6 col-lg-3 filtr-item"
                data-category={project.category}
                key={index}
              >
                {/*<NavLink to={`project/${slugify(project.name)}`}>*/}
                  <div className="project-item">
                    <div className="project-image">
                      <img
                        src={
                          project.projectSquareImage
                            ? project.projectSquareImage
                            : images.header.project
                        }
                        alt=""
                      />
                    </div>
                    <div className="project-over">
                      <div className="project-content">
                        <h4>{project.name} </h4>
                        <p>{project.category}</p>
                      </div>
                    </div>
                  </div>
                {/*</NavLink>*/}
              </div>
            ))}
        </div>
        <div className="project-area project-call section-m bg-bunker tc-light">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="d-lg-flex text-center text-lg-left align-items-center justify-content-between">
                  <h2 className="fw-4">Want to see our more creative work? </h2>
                  <NavLink to={'/projects'} className="btn rounded-sm">
                    See All Work
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkSection;
