import React from 'react';
import {NavLink} from "react-router-dom";

const CTASection = () => {
  return (
    <div className="section section-cta bg-primary tc-light">
      <div className="container">
        <div className="row gutter-vr-30px align-items-center justify-content-between">
          <div className="col-lg-8 text-center text-lg-left">
            <div className="cta-text">
              <h2>
                <strong>Ready to build your dream? </strong> We're just an email
                away.
              </h2>
            </div>
          </div>
          <div className="col-lg-4 text-lg-right text-center">
            <div className="cta-btn ">
              <NavLink to={"/contact"} className="btn rounded-sm">
                Contact us
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CTASection;
