import React, { useRef } from 'react';
import { images } from '../../assets';
import { NavLink } from 'react-router-dom';
import emailjs from '@emailjs/browser';
import { confirmAlert } from 'react-confirm-alert';

const Footer = () => {
  const emailRef = useRef();

  const options = {
    title: "We've received your message!",
    message:
      'Thank you for reaching out. We will get back to you as soon as possible.',
    buttons: [
      {
        label: 'Ok',
        onClick: () => {},
      },
    ],
    closeOnEscape: true,
    closeOnClickOutside: true,
    keyCodeForClose: [],
    overlayClassName: 'overlay-custom-class-name',
  };

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        'service_7j7aj56',
        'template_wey3vrc',
        emailRef.current,
        'Bmbvu7TnSWpQ_nC9b',
      )
      .then(
        (result) => {
          confirmAlert(options);
          e.target.reset();
        },
        (error) => {
          console.log(error.text);
        },
      );
  };

  return (
    <footer className="section footer bg-dark-alt tc-light footer-s1">
      <div className="container">
        <div className="row gutter-vr-30px">
          <div className="col-lg-3 col-sm-6">
            <div className="wgs">
              <div className="wgs-content">
                <div className="wgs-logo">
                  <a href="./">
                    <img
                      src={images.logoWhite}
                      srcSet={images.logoWhite}
                      alt="logo"
                    />
                  </a>
                </div>
                <p>
                  &copy; {new Date().getFullYear()}. All rights reserved. <br />
                  <NavLink to="/">Software From Space</NavLink>
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div className="wgs">
              <div className="wgs-content">
                {/*<h3 className="wgs-title">Company</h3>*/}
                <ul className="wgs-menu">
                  <li>
                    <NavLink to="/services">Services</NavLink>
                  </li>
                  <li>
                    <NavLink to="/projects">Our work</NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div className="wgs">
              <div className="wgs-content">
                <h3 className="wgs-title">Services</h3>
                <ul className="wgs-menu">
                  <li>
                    <NavLink to="/about">About us</NavLink>
                  </li>
                  <li>
                    <NavLink to="/contact">Contact</NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div className="wgs ">
              <div className="wgs-content ">
                <h3 className="wgs-title">Say hello</h3>
                <form
                  ref={emailRef}
                  className="genox-form"
                  onSubmit={sendEmail}
                >
                  <div className="form-results"></div>
                  <div className="field-group btn-inline">
                    <input
                      name="cf_email"
                      type="email"
                      className="input  rounded-sm"
                      placeholder="Your Email"
                    />
                    <input
                      type="text"
                      className="d-none"
                      name="form-anti-honeypot"
                      defaultValue=""
                    />
                    <button
                      type="submit"
                      className="far fa-paper-plane button"
                    ></button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 col-lg-12 text-md-right">
            <div className="copyright-content">
              <ul className="social social-style-icon">
                <li><NavLink target={"_blank"} to={"https://facebook.com/softwarefromspace"} href="" className="fab text-white fa-facebook-f"/></li>
                <li><NavLink target={"_blank"} to={"https://www.instagram.com/softwarefromspace"} className="fab  text-white fa-instagram"></NavLink></li>
                <li><NavLink  target={"_blank"} to={"https://www.linkedin.com/company/software-from-space/"} className="fab text-white fa-linkedin"></NavLink></li>
              </ul>
            </div>
          </div>
        </div>
      </div>

    </footer>
  );
};

export default Footer;
