import React, { useRef } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import emailjs from '@emailjs/browser';

import Banner from '../../components/Banner';
import { backgrounds } from '../../assets';

const Contact = () => {
  const form = useRef();
  const emailRef = useRef();
  const companyRef = useRef();
  const nameRef = useRef();
  const budgetRef = useRef();
  const messageRef = useRef();

  const options = {
    title: "We've received your message!",
    message:
      'Thank you for reaching out. We will get back to you as soon as possible.',
    buttons: [
      {
        label: 'Ok',
        onClick: () => {},
      },
    ],
    closeOnEscape: true,
    closeOnClickOutside: true,
    keyCodeForClose: [],
    overlayClassName: 'overlay-custom-class-name',
  };

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        'service_7j7aj56',
        'template_wey3vrc',
        form.current,
        'Bmbvu7TnSWpQ_nC9b',
      )
      .then(
        (result) => {
          confirmAlert(options);
          emailRef.current.value = '';
          nameRef.current.value = '';
          companyRef.current.value = '';
          budgetRef.current.value = `What's Your Budget`;
          messageRef.current.value = '';
        },
        (error) => {
          console.log(error.text);
        },
      );
  };

  return (
    <div>
      <Banner
        title={'BECAUSE WE ARE HUMAN AND VERY REAL'}
        subtitle={'YOU CAN CONTACT US'}
        image={backgrounds.header}
      />
      <div className="section section-x tc-bunker">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-lg-6">
              <div className="section-head">
                <h5 className="heading-xs dash">Fill the form</h5>
                <h2>Describe your project and leave us your contact info</h2>
              </div>
            </div>
          </div>
          <div className="row gutter-vr-30px">
            <div className="col-lg-5 order-lg-last">
              <div className="contact-text contact-text-s2 bg-secondary box-pad ">
                <div className="text-box">
                  <h4>SOFTWARE FROM SPACE SRL</h4>
                  <p className="lead">CLUJ NAPOCA, ROMANIA</p>
                </div>
                <ul className="contact-list">
                  <li>
                    <em className="contact-icon ti-mobile"></em>
                    <div className="conatct-content">
                      <a href="tel:0040773930788">+40 773 930 788</a>
                    </div>
                  </li>
                  <li>
                    <em className="contact-icon ti-email"></em>
                    <div className="conatct-content">
                      <a href="mailto:andrei@softwarefromspace.com">
                        andrei@softwarefromspace.com
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-7">
              <form
                ref={form}
                onSubmit={sendEmail}
                className="genox-form form-dark mt-10"
              >
                <div className="form-results"></div>
                <div className="row">
                  <div className="form-field col-md-6">
                    <input
                      ref={nameRef}
                      name="cf_name"
                      type="text"
                      placeholder="Your Name"
                      className="input bdr-b required"
                    />
                  </div>
                  <div className="form-field col-md-6">
                    <input
                      name="cf_email"
                      type="email"
                      required
                      ref={emailRef}
                      placeholder="Your Email"
                      className="input bdr-b required"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-field col-md-6">
                    <input
                      name="cf_company"
                      type="text"
                      ref={companyRef}
                      required
                      placeholder="Your Company"
                      className="input bdr-b required"
                    />
                  </div>
                  <div className="form-field form-select col-md-6">
                    <select
                      name="cf_budget"
                      ref={budgetRef}
                      className="form-control input-select bdr-b input required"
                      id="selectid_b"
                    >
                      <option>What's Your Budget</option>
                      <option>€20.000 - €50.000</option>
                      <option>€50.000 - €100.000</option>
                      <option>€100.000 - €200.000</option>
                      <option>More than $200.000</option>
                      <option>Can't disclose</option>
                    </select>
                  </div>
                </div>
                <div className="row">
                  <div className="form-field col-md-12">
                    <textarea
                      name="cf_msg"
                      ref={messageRef}
                      required
                      placeholder="Briefly tell us about your project. "
                      className="input input-msg bdr-b required"
                    ></textarea>
                    <input
                      type="text"
                      className="d-none"
                      name="form-anti-honeypot"
                      defaultValue=""
                    />
                  </div>
                </div>
                <input
                  type="submit"
                  onClick={sendEmail}
                  className="btn"
                  id="btn"
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
