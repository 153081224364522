import React, { useEffect, useState } from 'react';
import { images } from '../../assets';
import { NavLink } from 'react-router-dom';

const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeClass, setActiveClass] = useState('header-navbar');

  useEffect(() => {
    if (isOpen) {
      setActiveClass('header-navbar menu-mobile menu-shown');
    }
  }, [isOpen]);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
  });

  const handleClickOutside = (event) => {
    event.preventDefault();
    if (event.srcElement.className === 'header-navbar-overlay') {
      setIsOpen(false);
    }
  };

  const closeNavbar = () => {
    setIsOpen(false);
    setActiveClass('header-navbar');
  };

  const openNavbar = () => {
    setIsOpen(true);
    setActiveClass('header-navbar menu-mobile menu-shown');
  };

  return (
    <div>
      <header className="is-sticky is-shrink is-boxed header-s1" id="header">
        <div className="header-box">
          <div className="header-main">
            <div className="header-wrap">
              <div className="header-logo logo" style={{ width: '200px' }}>
                <NavLink to="/" className="logo-link">
                  <img
                    className="logo-light"
                    src={images.header.logo}
                    srcSet={images.header.logo}
                    alt="logo"
                  />
                  <img
                    className="logo-dark"
                    src={images.header.logo}
                    srcSet={images.header.logo}
                    alt="logo"
                  />
                </NavLink>
              </div>

              <div className="header-nav-toggle">
                <a
                  href="#"
                  onClick={() => {
                    isOpen ? closeNavbar() : openNavbar();
                  }}
                  data-menu-toggle="header-menu"
                  className={`navbar-toggle ${isOpen ? 'active' : ''}`}
                >
                  <div className="toggle-line">
                    <span />
                  </div>
                </a>
              </div>

              <div className={activeClass}>
                {isOpen ? (
                  <div
                    onClick={closeNavbar}
                    className="header-navbar-overlay"
                  ></div>
                ) : null}
                <nav className="header-menu" id="header-menu">
                  <ul className="menu">
                    <li className="menu-item">
                      <NavLink
                        onClick={closeNavbar}
                        className={({ isActive }) =>
                          isActive ? 'active' : 'menu-link nav-link'
                        }
                        to={'/'}
                      >
                        Home
                      </NavLink>
                    </li>
                    <li className="menu-item">
                      <NavLink
                        onClick={closeNavbar}
                        className={({ isActive }) =>
                          isActive ? 'active' : 'menu-link nav-link'
                        }
                        to={'/services'}
                      >
                        Services
                      </NavLink>
                    </li>
                    <li className="menu-item">
                      <NavLink
                        onClick={closeNavbar}
                        className={({ isActive }) =>
                          isActive ? 'active' : 'menu-link nav-link'
                        }
                        to={'/projects'}
                      >
                        Explorations
                      </NavLink>
                    </li>
                    <li className="menu-item">
                      <NavLink
                        onClick={closeNavbar}
                        className={({ isActive }) =>
                          isActive ? 'active' : 'menu-link nav-link'
                        }
                        to={'/about'}
                      >
                        About
                      </NavLink>
                    </li>
                    <li className="menu-item">
                      <NavLink
                        onClick={closeNavbar}
                        className={({ isActive }) =>
                          isActive ? 'active' : 'menu-link nav-link'
                        }
                        to={'/contact'}
                      >
                        Contact
                      </NavLink>
                    </li>
                    {/*<li className="menu-item">*/}
                    {/*  <NavLink*/}
                    {/*    className={({ isActive }) =>*/}
                    {/*      isActive ? 'active' : 'menu-link nav-link'*/}
                    {/*    }*/}
                    {/*    to="dallas-dark-news.html"*/}
                    {/*  >*/}
                    {/*    Blog*/}
                    {/*  </NavLink>*/}
                    {/*</li>*/}
                  </ul>
                  <ul className="menu-btns">
                    <li>
                      <NavLink
                        to="/contact"
                        className="btn btn-sm rounded-sm"
                        onClick={closeNavbar}
                      >
                        Get a quote
                      </NavLink>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

export default NavBar;
