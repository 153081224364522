import React from 'react';
import { NavLink } from 'react-router-dom';
import { backgrounds } from '../../assets';

const Banner = ({ title, subtitle, image, ctaText }) => {
  return (
    <div className="is-sticky is-shrink is-boxed header-s1" id="header">
      <div className="header-box">
        <div className="banner banner-inner tc-light">
          <div className="banner-block has-bg-image">
            <div className="container">
              <div className="row">
                <div className="col-md-9">
                  <div className="banner-content">
                    <h1 className="banner-heading m-0">{title}</h1>
                    <p className="lead m-0">{subtitle}</p>
                    {ctaText ? (
                      <NavLink to="/contact" className="btn rounded-sm">
                        {ctaText}
                      </NavLink>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <div
              className="bg-image overlay-fall bg-image-loaded"
              style={{
                backgroundImage: `url(${image})`,
              }}
            >
              <img src={image} alt="banner" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
