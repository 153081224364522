import React from 'react';
import { backgrounds } from '../../assets';
import { NavLink } from 'react-router-dom';
import AnimatedButton from '../AnimatedButton';
const Header = () => {
  return (
    <header className="is-sticky is-shrink is-boxed header-s1" id="header">
      <div className="header-box">
        <div className="banner tc-light">
          <div className="banner-block   has-bg-image">
            <div className="container">
              <div className="row ">
                <div className="col-md-10 col-xl-7 offset-xl-0">
                  <div className="banner-content">
                    <h1 className="banner-heading">
                      Take your product on an adventure
                    </h1>
                    <p className="lead lead-md">
                      We are a software development company with a human
                      approach
                    </p>
                    {/*<AnimatedButton />*/}
                    <div className="banner-btn">
                      <NavLink
                        to="/contact"
                        className="menu-link btn banner-btn-white rounded-sm"
                      >
                        Tell us your idea
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="bg-image bg-image-header change-bg overlay-fall bg-image-loaded"
              style={{
                backgroundImage: `url(${backgrounds.header})`,
              }}
            >
              <img src={backgrounds.header} alt="banner" />
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
