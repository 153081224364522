import React from 'react';
import { NavLink } from 'react-router-dom';

const WhatWeDo = () => {
  return (
    <div className="section section-x tc-bunker">
      <div className="container">
        <div className="row justify-content-between gutter-vr-30px">
          <div className="col-lg-4 col-xl-3 col-md-8">
            <div className="section-head section-head-col">
              <h5 className="heading-xs dash">What we do</h5>
              <h2>Innovations and impact</h2>
              <p className="lead">We put innovation in everything we do</p>
              <NavLink to="/services" className="btn rounded-sm">
                Discover More
              </NavLink>
            </div>
          </div>
          <div className="col-lg-8">
            <div className="row gutter-vr-30px gutter-vr-30px-res">
              <div className="col-sm-6">
                <div className="feature">
                  <div className="feature-icon">
                    <em className="icon ti-thought" />
                  </div>
                  <div className="feature-content">
                    <h3>Feature discovery</h3>
                    <p>
                      We help you discover the best and most important product
                      features that will transform your business into a success.
                    </p>
                    <NavLink to="/services" className="btn btn-arrow">
                      Read More
                    </NavLink>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="feature">
                  <div className="feature-icon">
                    <em className="icon ti-write" />
                  </div>
                  <div className="feature-content">
                    <h3>Web Development</h3>
                    <p>
                      At our core, we're all about bringing your idea to life in
                      a way that's completely tailored to you. That's why we put
                      communication and collaboration front and center
                      throughout the development process.
                    </p>
                    <NavLink to="/services" className="btn btn-arrow">
                      Read More
                    </NavLink>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="feature">
                  <div className="feature-icon">
                    <em className="icon ti-mobile" />
                  </div>
                  <div className="feature-content">
                    <h3>Mobile development</h3>
                    <p>
                      Our team of expert developers can bring your app idea to
                      life, whether it's for iOS, Android, or both. We use the
                      latest technologies and industry best practices to ensure
                      that your app is both functional and user-friendly, from
                      initial design to final launch.
                    </p>
                    <NavLink to="/services" className="btn btn-arrow">
                      Read More
                    </NavLink>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="feature">
                  <div className="feature-icon">
                    <em className="icon ti-bar-chart" />
                  </div>
                  <div className="feature-content">
                    <h3> Product Management</h3>
                    <p>
                      We'll work closely with you to ensure we meet your goals,
                      milestones, deadlines, and KPIs. Together, we'll stay on
                      track and make sure everything is right on target!
                    </p>
                    <NavLink to="/services" className="btn btn-arrow">
                      Read More
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhatWeDo;
