import React from 'react';
import { images } from '../../assets';

const AboutSection = () => {
  return (
    <div className="section section-x tc-bunker">
      <div className="container">
        <div className="row justify-content-between align-items-center gutter-vr-30px">
          <div className="col-xl-7 col-lg-6">
            <div className="image-block">
              <img src={images.illustrations.about} alt="" />
            </div>
          </div>
          <div className="col-xl-5 col-lg-6">
            <div className="text-block text-box-ml mtm-15">
              <h2>
                We are a software development company that focuses on any type
                of software products
              </h2>
              <p className="lead tc-light">
                With a keen eye on post MVP startups that require scale and
                adoption on the mass market, we can help you to unlock
                opportunity by creating human-centered products.
              </p>
              {/*<p>*/}
              {/*  Our engineers know collaboration is the essence of any project.*/}
              {/*  It’s a simple philosophy we followed and it delivers results.*/}
              {/*</p>*/}
              <a href="/about" className="btn rounded-sm">
                About
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSection;
