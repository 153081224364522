// Images
import logo from './images/logo/logo-color-small.png';
import logoWhite from './images/logo-white.png';
import logoSfs from './images/logo-sfs.png';
import project from './images/project-c.jpg';
import client from './images/client-a.png';
import astronaut from './images/anim_astronaut.png';
import team from './images/team-a.jpg';
import clientLogo from './images/client-dark-a.png';
import numbersBg from './images/bg-b.jpg';
import numbersBge from './images/header/bg-e.jpg';
import moon from './images/moon.jpeg';

// Backgrounds
import header from './images/header/header-3.png';
import header4 from './images/header/header-4.jpeg';
import singleProject from './images/single-project-banner.jpg';
import testbg from './images/banner-sm-g.jpg';

// Clients
import engage from './images/clients/engage_logo.png';
import sveinn from './images/clients/sveinn.png';
import kidgarten from './images/clients/kidgarten_logo.png';
import vibesmap from './images/clients/vibesmap_logo.png';
import salon365 from './images/clients/salon365_logo.png';
import celebro from './images/clients/celebro_logo.png';
import techstories from './images/clients/techstories.png';

// testimonials
import kidgarten_t from './images/clients/kidgarten_testimonial.png';
import salon_t from './images/clients/salon_testimonial.png';
import sveinn_t from './images/clients/sveinn_testimonial.png';



// Headers
import work from './images/header/work_header.png';

//Projects
import engage_project_sqare from './images/projects/engage/engage_project_square.png';
import vibesmap_project from './images/projects/vibesmap/vibesmap.png';
import salon365_project from './images/projects/salon365/salon_square.png';
import salon365_web_project from './images/projects/salon365/salon_web_square.png';
import techstories_web from './images/projects/techstories/techstories_sqare.png';
import kidgarten_web_sqare from './images/projects/kidgarten/kidgarten_web_sqare.png';

//Team
import andrei from './images/team/andrei.jpg';

//Who We Are
import whoWeAre from './images/whoweare/bg-3.jpeg';

// Illustration
import about_illustration from './images/illustration/about_illustration.png';
import who_we_are_illustration from './images/illustration/who_we_are_illustration.png';

export const icons = {};

export const images = {
  header: {
    logo,
    project,
  },
  testimonials: {
    kidgarten_t,
    salon_t,
    sveinn_t
  },
  clients: {
    engage,
    kidgarten,
    vibesmap,
    sveinn,
    salon365,
    celebro,
    techstories,
  },
  headers: {
    work,
  },
  team: {
    andrei,
  },
  projects: {
    engage: engage_project_sqare,
    kidgarten_web: kidgarten_web_sqare,
    vibesmap: vibesmap_project,
    salon365: salon365_project,
    salon365_web: salon365_web_project,
    techstories: techstories_web,
  },
  illustrations: {
    about: about_illustration,
    whoWeAre: who_we_are_illustration,
  },
  client,
  logoSfs,
  astronaut,
  logoWhite,
  clientLogo,
};

export const backgrounds = {
  header,
  header4,
  singleProject,
  numbersBge,
  numbersBg,
  testbg,
  whoWeAre,
  moon,
};
