import React from 'react';

const ProcessSection = () => {
  return (
    <div className="section section-x bg-bunker tc-bunker">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-12 text-center">
            <div className="section-head section-sm">
              <h5 className="heading-xs dash dash-both">Our Process</h5>
              <h2>We build products with strategy</h2>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-4 col-md-6 text-center">
            <div className="feature feature-s2">
              <div className="feature-icon-box">
                <div className="feature-icon feature-icon-s3">
                  <em className="icon ti-clipboard"></em>
                </div>
                <div className="feature-heading feature-heading-s2">
                  <h3>PLANNING</h3>
                </div>
              </div>
              <div className="feature-content-s2">
                <p>
                  Our approach involves dedicating ample time to defining your
                  idea, comprehending your business objectives, and recognizing
                  the requirements of your users.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 text-center">
            <div className="feature feature-s2">
              <div className="feature-icon-box">
                <div className="feature-icon feature-icon-s3">
                  <em className="icon ti-layers"></em>
                </div>
                <div className="feature-heading feature-heading-s2">
                  <h3>DESIGN AND BUILD</h3>
                </div>
              </div>
              <div className="feature-content-s2">
                <p>
                  The product testing team collaborates with the development
                  team to convert sketches, flows, and prototypes into
                  functional features. Then we iterate, build, and test again.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 text-center">
            <div className="feature feature-s2">
              <div className="feature-icon-box">
                <div className="feature-icon feature-icon-s3">
                  <em className="icon ti-layout"></em>
                </div>
                <div className="feature-heading feature-heading-s2">
                  <h3>LAUNCH</h3>
                </div>
              </div>
              <div className="feature-content-s2">
                <p>
                  After launching the first version of your product, our
                  development and design work continues to support your
                  strategic growth, ensuring your product remains relevant and
                  up-to-date. This is just the beginning of our journey
                  together.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ProcessSection;
