import React, { useState, useEffect } from 'react';

import {
  Outlet,
  createBrowserRouter,
  RouterProvider,
  useNavigation,
} from 'react-router-dom';
import Home from '../pages/Home';
import NotFoundPage from '../pages/NotFound';
import Preloader from '../components/Preloader';
import NavBar from '../components/NavBar';
// import ProjectsState from '../reducers/Projects';
// import Project from "../pages/Project/project";
// import Projects from "../pages/Projects/projects";
import Footer from '../components/Footer';
import About from '../pages/About';
import Services from '../pages/Services';
import Projects from '../pages/Projects';
import Contact from '../pages/Contact';

const Root = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <NavBar />
      <Outlet />
      <Footer />
    </>
  );
};

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    notFoundElement: <NotFoundPage />,
    errorElement: <NotFoundPage />,
    children: [
      { path: '/', element: <Home /> },
      { path: '/projects', element: <Projects /> },
      { path: '/services', element: <Services /> },
      { path: '/contact', element: <Contact /> },
      { path: '/about', element: <About /> },
    ],
  },
]);

const App = () => <RouterProvider router={router} />;

export default App;
