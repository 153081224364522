import React from 'react';
import { images } from '../../assets';
import clients from '../../data/clients';
const ClientsSection = () => {
  return (
    <div className="section section-x tc-bunker">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-4 text-center">
            <div className="section-head section-sm">
              <h5 className="heading-xs dash dash-both">Clients</h5>
              <h2>Our friends who love to work with us</h2>
            </div>
          </div>
        </div>
        <div className="row justify-content-center gutter-vr-30px">
          {clients.map((client, index) => (
            <a
              target="_blank"
              href={client.link}
              key={index}
              className="col-sm-3 col-4"
              rel="noreferrer"
            >
              <div className="logo-item">
                <img src={images.clients[client.logo]} alt="" />
              </div>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ClientsSection;
